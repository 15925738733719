import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Heading from '../../../../components/Heading/DesktopView';
import CreatePizzaCard from '../../../../components/CreatePizzaCard/DesktopView';
import HalvesCard from '../../../../components/HalvesCard/DesktopView';
import ProductCard from '../../../../components/ProductCard';

// Partials
import useProductList from '../useProductList';
import PizzaFilter from '../../PizzaFilter/DesktopView';

//Actions
import { changeBonuses } from '../../../../state/modules/user/actions';

//Utils
import { filterWillBurnPoints, useBonuses } from '../../../../utils/useBonuses';

// Styles
import styles from './ProductList.styl';

const cx = classNames.bind(styles);

export default function ProductList(props) {
  const isAuthenticated = useSelector((state) => {
    return state.user?.isAuthenticated;
  });

  const compositionCart = useSelector(state => state.cart?.composition);
  const history = useSelector(state => state.user?.profile?.loyalty?.history);
  const points = useSelector(state => state.user?.profile?.loyalty?.points);
  const {
    className, category, setIsOpenModalBonus,
    setIsOpenModalAuth,
  } = props;
  const { alias } = category;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && history && points) {
      const { willBurn } = filterWillBurnPoints(history, points);
      const bonuses = useBonuses(points, compositionCart, willBurn);
      dispatch(changeBonuses(bonuses));
    }
  }, [compositionCart, points, history]);

  const {
    goods,
    filteredStateGood,
    getGoodsWithoutDublicates,
    filteredGoods,
    filteredCategory,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    addToCart,
    sailplayGifts,
  } = useProductList(category);

  useEffect(() => {
    getGoodsWithoutDublicates();
  }, [goods]);

  const renderCard = good => {
    switch (good.alias) {
      case 'sozday': {
        return (
          <CreatePizzaCard
            key={good.id}
            className={cx('ProductList__item', 'ProductList__item_constructor')}
            name={good.name}
            id={good.id}
          />
        );
      }
      case 'sozday-vegan': {
        return (
          <CreatePizzaCard
            key={good.id}
            className={cx('ProductList__item', 'ProductList__item_constructor')}
            name={good.name}
            id={good.id}
          />
        );
      }
      case 'halves': {
        return (
          <HalvesCard
            key={good.id}
            className={cx('ProductList__item', 'ProductList__item_halves')}
            name={good.name}
          />
        );
      }
      default: {
        return (
          <ProductCard
            key={good.id}
            sailplayGifts={sailplayGifts}
            className={cx('ProductList__item')}
            good={good}
            removeIngredientAction={removeIngredientAction}
            cancelRemovedIngredientsAction={cancelRemovedIngredientsAction}
            addToCart={addToCart}
            isFromProductList
            setIsOpenModalBonus={setIsOpenModalBonus}
            setIsOpenModalAuth={setIsOpenModalAuth}
          />
        );
      }
    }
  };

  return (
    <section className={cx('ProductList', className)} id={alias}>
      <PizzaFilter name={category.filter((item) => item.alias === filteredCategory[0])[0]?.name}
                   className={cx('ProductList__section')}
                   changeFilterPizza={filteredGoods}
                   goods={goods}
                   id={filteredCategory[0]} />
      {category.map((item) => {
        return (
          filteredCategory.includes(item.alias) && (
            <section id={item.alias !== category[0].alias && item.alias}>
              {item.alias !== filteredCategory[0] &&
                <Heading className={cx('ProductList__heading')} level={2} tagName="h4">
                  {item.name}
                </Heading>}
              <div className={cx('ProductList__list')}>
                {
                  filteredStateGood?.map(elem => {
                    if (elem.category === item.alias) {
                      return renderCard(elem);
                    }
                  })
                }
              </div>
            </section>
          ));
      })}
    </section>
  );
};

ProductList.defaultProps = {
  className: '',
};

ProductList.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object.isRequired,
};
