import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Router
import { paths, getRoute } from '../../../entry/routes';

// Components
import Heading from '../../Heading/DesktopView';
import Button from '../../Button';

// Icons
import cover from '../assets/cover.png';

// Styles
import styles from './CreatePizzaCard.styl';

const cx = classNames.bind(styles);

export default function CreatePizzaCard(props) {
  const { className, id, name } = props;

  const intl = useIntl();

  return (
    <div className={cx('CreatePizzaCard', className)} data-test-id="create_pizza_card">
      <Heading className={cx('CreatePizzaCard__heading')} level={3}>
        {name}
      </Heading>
      <LazyLoadImage className={cx('CreatePizzaCard__picture')} alt={name} src={cover} />
      <Button
        className={cx('CreatePizzaCard__button')}
        to={(id === 15) ? getRoute(paths.constructorAliasNotId) : getRoute(paths.constructorAlias, id)}
        secondary
      >
        {intl.formatMessage({ id: 'button.createPizza' })}
      </Button>
      <div className={cx('CreatePizzaCard__note')}>
        {intl.formatMessage({ id: 'constructor.slogan' })}
      </div>
    </div>
  );
}

CreatePizzaCard.defaultProps = {
  className: '',
};

CreatePizzaCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};
