import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames/bind';

// Components
import Heading from '../../Heading/DesktopView';
import Button from '../../Button';

// Router
import { paths, getRoute } from '../../../entry/routes';

// Icons
import cover from '../assets/cover.png';

// Styles
import styles from './HalvedCard.styl';

const cx = classNames.bind(styles);

export default function HalvesCard(props) {
  const { className, name } = props;

  const intl = useIntl();

  return (
    <div className={cx('HalvesCard', className)} data-test-id='halves_card'>
      <Heading className={cx('HalvesCard__heading')} level={3}>
        {name}
      </Heading>
      <LazyLoadImage className={cx('HalvesCard__picture')} alt={name} src={cover} />
      <Button className={cx('HalvesCard__button')} to={getRoute(paths.halves)} secondary>
        {intl.formatMessage({ id: 'button.choosePizza' })}
      </Button>
      <div className={cx('HalvesCard__note')}>{intl.formatMessage({ id: 'halves.slogan' })}</div>
    </div>
  );
}

HalvesCard.defaultProps = {
  className: '',
  name: '',
};

HalvesCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};
